<template>
    <div class="articles">
        <PageNavBar :title="categoryTitle" :goBack="goBackAndRemoveKeepAlive"/>

        <article-pull-list :categoryId="categoryId" ></article-pull-list>
    </div>
</template>

<script>
import PageNavBar from '../../components/PageNavBar'
import ArticlePullList from '../../components/ArticlePullList'
import { keepAliveMixin } from '../../mixins'

export default {
    name: 'ArticleListPage',
    mixins: [keepAliveMixin],
    components: {
        PageNavBar,
        ArticlePullList
    },
    props: {
    },
    setup() {
    },
    mounted() {
    },
    data() {
        return {
            categoryId: '0',
            categoryTitle: ''
        }
    },
    created() {
        let { id, title } = this.$route.params
        this.categoryId = id
        this.categoryTitle = title
    },
    methods: {
    }
}
</script>

<style lang="stylus" scoped>
</style> 